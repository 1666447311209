import { extendTheme } from "@chakra-ui/react";
import "@fontsource/mulish";
import "@fontsource/inter";

const theme = {
  colors: {
    pink: "#FFF5EE",
    dkBlue: "#26275E",
    dkGrey: "#3b3b3b",
    btOrange: "rgba(147, 70, 66, 0.89)",
    ltOrange: "#F5F5F5",
    gray: "#D1D0D6",
    green: "#C5D3D1",
    white: "#fff",
  },
  fonts: {
    heading: "Inter, sans-serif",
    body: "Mulish, sans-serif",
    number: "Mulish, sans-serif",
  },
  components: {
    Link: {
      baseStyle: {
        _hover: { color: "btOrange", textDecoration: "underline" },
        _active: { textDecoration: "underline" },
        _focus: { textDecoration: "underline" },
        color: "#EECA83",
        fontWeight: "bold", // Normally, it is "semibold"
      },
    },
    Button: {
      variants: {
        collapsible: {
          bg: "white",
          color: "dkGrey",
          boxShadow: "none",
          fontWeight: "700",
          height: "42px",
          colorScheme: "orange",
          variant: "solid",
          _hover: {
            color: "#fff",
            bg: "gray",
          },
          border: "1px solid gray",
          boxShadow: "none",
          fontSize: ["10px", "16px"],
          fontFamily: "heading",
          w: "100%",
          borderRadius: "0px",
          whiteSpace: "wrap",
        },
        dark: {
          color: "#fff",
        },
      },
    },
  },
};

export default extendTheme(theme);
